import React from "react"
import styled from "@emotion/styled"


const Container = styled.div`
  padding: 3rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  transition: all 0.2s;
  z-index: 3;
  width: 100%;
  position: relative;
`

const Card = ({ children }) => (
  <Container className="card">{children}</Container>
)

export default Card
