import React from "react"
import styled from "@emotion/styled"


const Container = styled.div`
  z-index: 2;
  background-color: #fff;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  align-items: center;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  padding-bottom: 1.5rem;
`

const Body = styled.p`
  padding: 1.5rem 0;
  font-weight: 400;
`

const PostSubmit = ({ title, children }) => {
  return (
    <Container>
      <Secondary>{title}</Secondary>
      <Body>{children}</Body>
    </Container>
  )
}

export default PostSubmit
