import React, { createContext, useState, useEffect } from "react"
import publicIp from "public-ip"

export const LocationContext = createContext()

const cityArea = {
  calgary: [
    "academy",
    "airdrie",
    "balzac",
    "banff",
    "blackfalds",
    "braggCreek",
    "chestermere",
    "calgary",
    "canmore",
    "cochrane",
    "deWinton",
    "innisfail",
    "okotoks",
    "redDeer"
  ],
  edmonton: [
    "beaumont",
    "edmonton",
    "fortMcmurray",
    "fortSaskatchewan",
    "leduc",
    "nisku",
    "sherwoodPark",
    "spruceGrove",
    "stAlbert",
    "stoneyPlain",
  ],
  lethbridge: [
    "lethbridge",
    "coaldale",
    "coalhurst",
  ]
}

export const LocationContextProvider = ({ children }) => {
  //Default location is Calgary
  const [loc, setLoc] = useState({
    city: "calgary",
    area: "calgary",
    province: "alberta",
    serviced: true,
  })

  //If location is allowed on the device, get location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setCity, getPositionFromIp)
    }
  }, [])

  async function handleSetLoc(city, province) {
    let area,
      serviced = false

    //If location is changed through dropdown, update area
    for (let key of Object.keys(cityArea)) {
      if (cityArea[key].includes(city)) {
        area = key
        serviced = true
        break
      }
    }

    setLoc({ city, province, area, serviced })
  }

  async function setCity(position) {
    const res = await fetch(
      `https://us1.locationiq.com/v1/reverse.php?key=${process.env.GATSBY_LOCATIONIQ_API}&lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
    )
    if (res.status === 200) {
      const json = await res.json()
      const city =
        json.address.city ||
        json.address.town ||
        json.address.village ||
        "City undefined"
      const province = json.address.state || "Province undefined"

      handleSetLoc(toCamelCase(city), toCamelCase(province))
    }
  }

  async function getPositionFromIp() {
    const ip = await publicIp.v4()
    const res = await fetch(`https://ipapi.co/${ip}/json`)
    if (res.ok) {
      const json = await res.json()
      setCity({
        coords: { latitude: json.latitude, longitude: json.longitude },
      })
    }
  }

  return (
    <LocationContext.Provider value={{ loc, handleSetLoc }}>
      {children}
    </LocationContext.Provider>
  )
}

function toCamelCase(string) {
  string = string
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
      return match.charAt(match.length - 1).toUpperCase()
    })
  return string.charAt(0).toLowerCase() + string.substring(1)
}
